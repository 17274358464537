// import Swiper from 'Swiper';
import 'sticky-kit/dist/sticky-kit.min.js';
import Swiper from 'swiper/bundle';

export default {
  init() {
    // JavaScript to be fired on the home page
  },
  finalize() {
    var frontPageHeader = document.getElementById('frontpageheader');
    var frontPageHeaderHeight = frontPageHeader.offsetHeight;

    // Image Galery Header Thumbnails
    var productsliderContainer = document.querySelectorAll(
      '.swiper-container.productslider'
    );

    if (productsliderContainer) {
      productsliderContainer.forEach(function (productsliderContainer) {
        // let swiperID = houseintroThumbsContainer.getAttribute('data-swiperid');

        let productSwiper = new Swiper(productsliderContainer, {
          loop: false,
          direction: 'horizontal',
          freeMode: false,
          spaceBetween: 40,
          slidesPerView: 1,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
          },
        });

        productSwiper.update();
      });
    }

    $(window).on('scroll', function () {
      var bodyClasses = document.getElementById('body-classes');
      if (bodyClasses.classList.contains('front-page-data')) {
        if ($(window).scrollTop() <= frontPageHeaderHeight - 30) {
          bodyClasses.classList.add('transparentheader');
          // headerbar.classList.remove('js__menubar--sticky');
          $('#headerbar').trigger('sticky_kit:detach');
        } else {
          bodyClasses.classList.remove('transparentheader');
          // headerbar.classList.add('js__menubar--sticky');

          $('#headerbar').stick_in_parent({
            bottoming: true,
            offset_top: 0,
          });
        }
      }
    });
    // JavaScript to be fired on the home page, after the init JS
  },
};

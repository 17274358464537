import mapboxgl from 'mapbox-gl/dist/mapbox-gl.js';

export default {
  init() {
    function buildMap() {
      // init Map
      mapboxgl.accessToken =
        ajax_object.access_token;

      var map = new mapboxgl.Map({
        container: 'mapbox',
        style: 'mapbox://styles/alpha-alpaka/ckllaxolx29g217qoojdbis6u',
        zoom: 5,
        pitch: 20,
        center: [10.32545, 50.974419],
      });

      map.scrollZoom.disable();

      // Add a marker to the map for every store listing.
      function addMarkers() {
        const markerFrankfurt = document.createElement('div');
        markerFrankfurt.className =
          'marker w-3 h-3 icon--medium icon--mapmarker';

        const markerPotsdam = document.createElement('div');
        markerPotsdam.className = 'marker w-3 h-3 icon--medium icon--mapmarker';

        const markerBretten = document.createElement('div');
        markerBretten.className = 'marker w-3 h-3 icon--medium icon--mapmarker';

        // Frankfurt
        new mapboxgl.Marker(markerFrankfurt)
          .setLngLat([8.66957, 50.09621])
          .addTo(map);

        // Potsdam
        new mapboxgl.Marker(markerPotsdam)
          .setLngLat([13.05854, 52.396149])
          .addTo(map);

        // Bretten
        new mapboxgl.Marker(markerBretten)
          .setLngLat([8.70973, 49.03618])
          .addTo(map);
      }

      map.on('load', function () {
        /**
         * This is where your '.addLayer()' used to be, instead
         * add only the source without styling a layer
         */

        map.addControl(
          new mapboxgl.NavigationControl({ compact: true }),
          'bottom-right'
        );

        /**
         * Add all the things to the page:
         * - The location listings on the side of the page
         * - The markers onto the map
         */
        addMarkers();
      });
    }

    buildMap();
  },
};

require('@lottiefiles/lottie-player');

export default {
  init() {
    // JavaScript to be fired on the about page
  },
  finalize() {
    // JavaScript to be fired on the about page, after the init JS
  },
};

export default {
  init() {
    // JavaScript to be fired on all pages
    console.log('countdown');
  },
  finalize() {
    const element = document.getElementById('frontpageheader');
    const dataAttribute = element.getAttribute('data-date');
    const countToDate = new Date(dataAttribute);

    setInterval(() => {
      const currentDate = new Date()
      const timeBetweenDates = Math.ceil((countToDate - currentDate) / 1000)
      flipAllCards(timeBetweenDates)
    }, 250)

    function flipAllCards(time) {
      const seconds = time % 60
      const minutes = Math.floor(time / 60) % 60
      const days = Math.floor(time / (3600 * 24))
      const hours = Math.floor((time - (days * 3600 * 24)) / 3600)

      flip(document.querySelector('[data-days-tens]'), Math.floor(days / 10))
      flip(document.querySelector('[data-days-ones]'), days % 10)
      flip(document.querySelector('[data-hours-tens]'), Math.floor(hours / 10))
      flip(document.querySelector('[data-hours-ones]'), hours % 10)
      flip(document.querySelector('[data-minutes-tens]'), Math.floor(minutes / 10))
      flip(document.querySelector('[data-minutes-ones]'), minutes % 10)
      flip(document.querySelector('[data-seconds-tens]'), Math.floor(seconds / 10))
      flip(document.querySelector('[data-seconds-ones]'), seconds % 10)
    }

    function flip(flipCard, newNumber) {
      const topHalf = flipCard.querySelector('.top')
      const startNumber = parseInt(topHalf.textContent)
      if (newNumber === startNumber) return

      const bottomHalf = flipCard.querySelector('.bottom')
      const topFlip = document.createElement('div')
      topFlip.classList.add('top-flip')
      const bottomFlip = document.createElement('div')
      bottomFlip.classList.add('bottom-flip')

      top.textContent = startNumber
      bottomHalf.textContent = startNumber
      topFlip.textContent = startNumber
      bottomFlip.textContent = newNumber

      topFlip.addEventListener('animationstart', () => {
        topHalf.textContent = newNumber
      })
      topFlip.addEventListener('animationend', () => {
        topFlip.remove()
      })
      bottomFlip.addEventListener('animationend', () => {
        bottomHalf.textContent = newNumber
        bottomFlip.remove()
      })
      flipCard.append(topFlip, bottomFlip)
    }
  },
};

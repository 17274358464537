import Swiper from 'swiper/bundle';

export default {
  init() {
    // JavaScript to be fired on all pages
  },
  finalize() {
    // Image Galery Header Thumbnails
    var logosliderContainer = document.querySelectorAll(
      '.swiper-container.logoslider'
    );

    if (logosliderContainer) {

      logosliderContainer.forEach(function (logosliderContainer) {

        let logoSwiperConfig = {
          loop: true,
          watchSlidesVisibility: true,
          watchSlidesProgress: true,
          direction: 'horizontal',
          freeMode: true,
          slidesPerView: 'auto',
          autoplay: {
            delay: 2500,
            reverseDirection: false,
          },
        };

        const direction = logosliderContainer.getAttribute('data-direction');

        if (direction === 'backwards') {
          logoSwiperConfig.autoplay.reverseDirection = true;
        }

        let logoSwiper = new Swiper(logosliderContainer, logoSwiperConfig);

        logoSwiper.update();
      });
    }
  },
};

// import external dependencies
import 'jquery';

// Import everything from autoload
;

// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import aboutUs from './routes/about';
import logoslider from './routes/logoslider';
import countdown from './routes/countdown';
import blockMap from './routes/map';
import blockLottie from './routes/lottie';

/** Populate Router instance with DOM routes */
const routes = new Router({
  common,
  home,
  aboutUs,
  logoslider,
  countdown,
  blockMap,
  blockLottie,
});

// Load Events
jQuery(document).ready(() => {
  routes.loadEvents();
});

import Rellax from 'rellax';
import 'sticky-kit/dist/sticky-kit.min.js';
import SmoothScroll from 'smooth-scroll/dist/smooth-scroll.min.js';
import anime from 'animejs';
import ScrollReveal from 'scrollreveal';
import Swiper from 'swiper/bundle';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

// eslint-disable-next-line no-undef
gsap.registerPlugin(ScrollTrigger);

export default {
  init() {
    // JavaScript to be fired on all pages
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired

    var bodyClasses = document.getElementById('body-classes');

    // Smooth Scroll
    new SmoothScroll('a[href="#contactform"]', {
      updateURL: false,
      popstate: false,
      offset: 120,
      clip: true,
    });

    // Smooth Scroll
    new SmoothScroll('a[href*="#"]', {
      updateURL: false,
      popstate: false,
      offset: 0,
      clip: true,
    });

    if ($('.js__menubar--sticky').length) {
      // no menu-spacer for frontpage
      if (bodyClasses.classList.contains('front-page-data')) {
        $('.js__menubar--sticky').stick_in_parent({
          bottoming: true,
          offset_top: 0,
          spacer: false,
        });
      } else {
        $('.js__menubar--sticky').stick_in_parent({
          bottoming: true,
          offset_top: 0,
        });
      }
    }

    function countup() {
      const counters = document.querySelectorAll('.js__counter');

      counters.forEach((counter) => {
        anime({
          targets: counter,
          innerHTML: [0, counter.getAttribute('data-count')],
          easing: 'easeInOutSine',
          round: 1,
          duration: 2000,
        })
      });
    }

    ScrollReveal().reveal('.js__countup_trigger', countup());

    function revealElements() {
      ScrollReveal().reveal('.reveal', {
        delay: 200,
        duration: 450,
        distance: '16px',
        interval: 50,
        easing: 'cubic-bezier(0.5, 0, 0, 1)',
      });
    }

    revealElements();

    function revealTimeline() {
      ScrollReveal().reveal('.reveal--timeline', {
        delay: 200,
        duration: 450,
        distance: '16px',
        interval: 50,
        easing: 'cubic-bezier(0.5, 0, 0, 1)',
      });
    }

    function revealTimelineLeft() {
      ScrollReveal().reveal('.reveal--timeline-left', {
        origin: 'left',
        delay: 1000,
        duration: 450,
        distance: '16px',
        interval: 50,
        easing: 'cubic-bezier(0.5, 0, 0, 1)',
      });
    }

    function revealTimelineRight() {
      ScrollReveal().reveal('.reveal--timeline-right', {
        origin: 'right',
        delay: 1000,
        duration: 450,
        distance: '16px',
        interval: 50,
        easing: 'cubic-bezier(0.5, 0, 0, 1)',
      });
    }

    function isInViewport(el) {
      const rect = el.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
        (window.innerHeight / 2 || document.documentElement.clientHeight) &&
        rect.right <=
        (window.innerWidth || document.documentElement.clientWidth)
      );
    }

    function highlightTimeLineEvent() {
      const timeLineElements = document.querySelectorAll(
        '.reveal--timeline-element'
      );

      document.addEventListener('scroll', function () {
        timeLineElements.forEach((timelineElement) => {
          if (isInViewport(timelineElement)) {
            timeLineElements.forEach((timelineElementEach) => {
              timelineElementEach.classList.add(
                'bg-gray-100',
                'text-secondary'
              );
              timelineElementEach.classList.remove('bg-primary', 'text-white');
            });

            timelineElement.classList.remove('bg-gray-100', 'text-secondary');
            timelineElement.classList.add('bg-primary', 'text-white');
          } else {
            timelineElement.classList.add('bg-gray-100', 'text-secondary');
            timelineElement.classList.remove('bg-primary', 'text-white');
          }
        });
      }),
      {
        passive: true,
      };
    }

    if ($('.block-timeline').length) {
      revealTimeline();
      revealTimelineLeft();
      revealTimelineRight();
      highlightTimeLineEvent();
    }

    // Mobile Menu
    $(document)
      .off('click', '.js__openmenu')
      .on('click', '.js__openmenu', function (e) {
        e.preventDefault();
        togglemenu();
      });

    // Submenu
    $('.nav-primary').on('click', '.menu-item-has-children > a', function (e) {
      if ($(window).width() < 1024) {
        var openThis = true;

        if (
          $(this).parent('.menu-item-has-children').hasClass('activeMobile')
        ) {
          openThis = false;
        }

        $('.menu-item-has-children').removeClass('activeMobile');

        if (openThis) {
          $(this).parent('.menu-item-has-children').addClass('activeMobile');
        }

        e.stopImmediatePropagation();
        e.preventDefault();
      }
    });

    // toggle Menu - toggle bodyclass
    function togglemenu() {
      if ($('#body-classes').hasClass('mobilemenu--open')) {
        anime({
          targets: '#menu-mainmenu > li',
          translateX: [0, 100],
          opacity: [1, 0],
          easing: 'easeInOutQuad',
          loop: false,
          duration: 100,
          delay: anime.stagger(50),
          complete: function () {
            $('#body-classes').removeClass('mobilemenu--open');
            $('html').removeClass('noscroll');
          },
        });
      } else {
        $('#body-classes').addClass('mobilemenu--open');
        $('html').addClass('noscroll');

        anime({
          targets: '#menu-mainmenu > li',
          translateX: [-100, 0],
          opacity: [0, 1],
          easing: 'easeInOutQuad',
          loop: false,
          duration: 300,
          delay: anime.stagger(100),
          complete: function () { },
        });
      }
      return false;
    }

    // Accordions
    var accordions = document.querySelectorAll('.aa-accordion--container');

    function accordionToggle(
      accordionActiveElement,
      accordions,
      scrollToActiveContainer
    ) {
      var clickOnActiveElement = false;

      if (accordionActiveElement.classList.contains('active')) {
        clickOnActiveElement = true;
      }

      accordions.forEach((accordion) => {
        accordion.classList.remove('active');

        if (clickOnActiveElement == true) {
          var accordionActiveElementContent =
            accordionActiveElement.querySelectorAll('.aa-accordion--content');
          accordionActiveElementContent[0].style.height = 0;
          return;
        }

        let thisAccordionContent = accordion.querySelectorAll(
          '.aa-accordion--content'
        );

        anime({
          targets: thisAccordionContent[0],
          height: 0,
          easing: 'easeOutExpo',
          loop: false,
          duration: 200,
          complete: function () {
            accordionActiveElement.classList.add('active');
            var accordionActiveElementContent =
              accordionActiveElement.querySelectorAll('.aa-accordion--content');

            accordionActiveElementContent[0].style.height = 'auto';
            let height = accordionActiveElementContent[0].clientHeight;
            accordionActiveElementContent[0].style.height = 0;

            anime({
              targets: accordionActiveElementContent,
              height: [0, height],
              easing: 'easeOutExpo',
              loop: false,
              duration: 200,
              complete: function () {
                if (scrollToActiveContainer) {
                  var scroller = new SmoothScroll();
                  scroller.animateScroll(accordionActiveElement, false, {
                    speed: 400,
                    easing: 'easeOutCubic',
                    offset: 150,
                    mitEvents: false, // Emit custom events
                  });
                }
              },
            });
          },
        });
      });
    }

    // textandboxedimage block

    $('.hiddenbuttonreveal').on('click', function (e) {
      e.preventDefault();
      $('.aa-hiddentext').addClass('active');
      $('.hiddenbuttonreveal').addClass('hidden');
    });

    if (accordions.length) {
      // accordion click handler
      accordions.forEach((accordion) => {
        let accordionHeader = accordion.querySelectorAll(
          '.aa-accordion--header'
        );

        accordionHeader[0].addEventListener('click', function (event) {
          event.preventDefault();
          accordionToggle(this.parentNode, accordions, false);
        });
      });
    }

    $('.aa-accordion .js__accordion--showall').on('click', function (e) {
      e.preventDefault();
      $(this).addClass('hidden');

      $('.aa-accordion .aa-accordion--hidden-mobile').each(function () {
        $(this).removeClass('aa-accordion--hidden-mobile');
      });
    });

    // Parallax
    const parallaxContainer = document.querySelectorAll('.js__parallax');
    Array.from(parallaxContainer).forEach((element) => {
      new Rellax(element, {
        // speed: -4,
        // center: true,
      });
    });

    // Image Galery Header Thumbnails
    var productsliderContainer = document.querySelectorAll(
      '.swiper-container.productslider'
    );

    if (productsliderContainer) {
      productsliderContainer.forEach(function (productsliderContainer) {
        // let swiperID = houseintroThumbsContainer.getAttribute('data-swiperid');

        let productSwiper = new Swiper(productsliderContainer, {
          loop: false,
          direction: 'horizontal',
          freeMode: false,
          spaceBetween: 40,
          slidesPerView: 1,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
          },
        });

        productSwiper.update();
      });
    }

    // Slider Employee Block
    var sliderEmployee = document.querySelectorAll(
      '.swiper-container.employeeSlider'
    );

    if (sliderEmployee) {
      sliderEmployee.forEach(function (sliderEmployee) {
        let employeeSwiper = new Swiper(sliderEmployee, {
          loop: false,
          direction: 'horizontal',
          freeMode: false,
          spaceBetween: 40,
          slidesPerView: 1,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
          },
        });

        employeeSwiper.update();
      });
    }

    // Slider Cards Block
    var sliderCards = document.querySelectorAll(
      '.swiper-container.sliderCards'
    );

    if (sliderCards) {
      sliderCards.forEach(function (sliderCards) {
        let cardsSwiper = new Swiper(sliderCards, {
          direction: 'horizontal',
          freeMode: false,
          spaceBetween: 32,
          slidesPerView: 1,
          loop: true,
          autoplay: {
            delay: 10000,
          },
          breakpoints: {
            // when window width is >= 760px
            760: {
              slidesPerView: 'auto',
            },
          },
          slideToClickedSlide: true,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
          },
        });

        cardsSwiper.update();

        cardsSwiper.on('slideChangeTransitionStart', function () {
          $('.swiper-container.sliderCards .aa--swiper-readmore').removeClass(
            'hidden'
          );
          $('.swiper-container.sliderCards .aa--swiper-textfold').addClass(
            'hidden'
          );
        });
      });

      $('.swiper-container.sliderCards .aa--swiper-readmore').on(
        'click',
        function (e) {
          e.preventDefault();
          if (
            $(this).parents('.swiper-slide').hasClass('swiper-slide-active')
          ) {
            $(this).addClass('hidden');
            $(this).siblings('.aa--swiper-textfold').removeClass('hidden');
          }
        }
      );
    }

    // timeline readmore
    $('.block-timeline .aa--timeline-readmore').on('click', function (e) {
      e.preventDefault();
      $(this).addClass('hidden');
      $(this).siblings('.aa--timeline-textfold').removeClass('hidden');
    });

    $('.block-timeline-horizontal .aa--timeline-readmore').on(
      'click',
      function (e) {
        e.preventDefault();
        $(this).addClass('hidden');
        $(this).siblings('.aa--timeline-textfold').removeClass('hidden');
      }
    );

    function formFeedback(message) {
      $('.messageParent').removeClass('hidden');
      $('.message')
        .removeClass('hidden')
        .text(message)
        .addClass('border-white')
        .addClass('mt-2')
        .removeClass('border-white');
    }

    // Newsletterblock Submit
    $('form#mailchimp').submit(function (e) {

      if (!this.checkValidity()) {
        event.preventDefault();
        formFeedback(ajax_object.newsletterRequired);
        return false;
      }

      e.preventDefault();
      var form = $(this);

      $(form).prepend(
        '<input type="hidden" name="action" value="mailchimpsubscription">'
      );

      var formData = $(form).serialize();
      $('.spinner').removeClass('hidden');
      $('.messageParent').addClass('hidden').removeClass('border');
      $('.message').text('');

      $.ajax({
        url: ajax_object.ajax_url,
        method: 'GET',
        data: formData,
        success: function (data) {
          setTimeout(() => {
            form.addClass('hidden');
            $('.spinner').addClass('hidden');
            formFeedback(data.message);
          }, 3000);
        },
        error: function (data) {
          setTimeout(() => {
            $('.spinner').addClass('hidden');
            formFeedback(data.responseJSON.message);
          }, 3000);
        },
      });
      return false;
    });

    // JavaScript to be fired on all pages, after page specific JS is fired
    $(window).on('load resize', function () {
      if ($(window).width() >= 768) {
        $('#body-classes').removeClass('mobilemenu--open');
        $('body').removeClass('noscroll');
      }

      // update TimelineSwiper
      timeLineSwiperInitAndUpdate();

      // update all swipers on load or resize
      const swiper = document.querySelectorAll('.swiper');
      if (swiper.length) {
        Array.from(swiper).forEach((element) => {
          element.swiper.update();
        });
      }
    });

    // Timeline swiper
    function timeLineSwiperInitAndUpdate() {
      var timelineswiperContainer = document.querySelectorAll(
        '.swiper-container.timelineswiper'
      );

      timelineswiperContainer.forEach(function (timelineswiperContainer) {
        if ($('#timelinespacer')) {
          var swiperOffset = $('#timelinespacer').offset().left + 16;
        }

        if (timelineswiperContainer) {
          if (typeof timelineswiperContainer.swiper === 'undefined') {
            new Swiper(timelineswiperContainer, {
              loop: false,
              freeMode: false,
              grabCursor: true,
              spaceBetween: 32,
              direction: 'horizontal',
              slidesOffsetBefore: swiperOffset,
              observer: true,
              breakpoints: {
                320: {
                  slidesPerView: 1.5,
                },
                700: {
                  slidesPerView: 2.5,
                },

                1280: {
                  slidesPerView: 3.5,
                },
              },
            });
          } else {
            timelineswiperContainer.swiper.params.slidesOffsetBefore =
              swiperOffset;
          }

          timelineswiperContainer.swiper.on('slideChange', function () {
            $('.block-timeline-horizontal .aa--timeline-textfold').addClass(
              'hidden'
            );
            $('.block-timeline-horizontal .aa--timeline-readmore').removeClass(
              'hidden'
            );
          });
          timelineswiperContainer.swiper.update();
        }
      });
    }

    var videos = document.querySelectorAll('.video-wrapper');

    if (videos) {
      videos.forEach(function (video) {
        video.addEventListener('click', function () {
          console.log(video.paused);
          if (video.children[1].paused === false) {
            video.children[0].classList.remove('hidden');
            video.children[1].pause();
            console.log(video.children[1]);
          } else {
            video.children[0].classList.add('hidden');
            video.children[1].play();
            console.log(video.children[1]);
          }
        });

        if (video.children[1].classList.contains('autoplay')) {
          ScrollTrigger.create({
            trigger: video,
            start: 'top center',
            onEnter: () => {
              video.children[0].classList.add('hidden');
              video.children[1].play();
            },
          });
        }
      });
    }
  },
};
